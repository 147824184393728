<template>
  <ViewTopNav :heading="this.viewType" :subheading="this.assetType" />
  
    <div class="position-absolute p-content z-1">
        <div class="col-12 p-0 z-1 h-100">
            <div class="row g-0 h-100">

                <div class="col-12 col-md-6 col-lg-4 m-auto" v-for="asset in assetTypes" :key="asset.guid">
                    <div class="row g-0">
                        <div class="col-12 position-relative z-1">

                            <!-- Videos -->
                            <router-link
                            v-if="viewType === 'videos' && collectionId"
                            :to="{
                                name:  'VideoView',
                                params: {
                                guid: asset.guid,
                                collectionId: asset.videoLibraryId
                                }
                            }"
                            class="v-link"
                            >
                            <img :src="theme.streamCdnUrl + '/' + asset.guid + '/' + asset.thumbnailFileName + '?width=700'" class="img-fluid">
                            <div class="render-name">
                                                    {{ asset.title }}
                                                </div>
                            </router-link>

                            <!-- Renders -->
                            <router-link
                            v-if="viewType === 'renders' || viewType === 'range'"
                            :to="{
                                name:  'AssetViewDetail',
                                params: {
                                assetType: this.assetType,
                                viewType: this.viewType,
                                order: asset.id
                                }
                            }"
                            class="r-link"
                            >
                            <img :src="theme.cdnUrl + '/assets/' + this.assetType + '/' + this.viewType + '/' + asset.LinkName + '?width=700'" class="img-fluid">
                            <div class="render-name">
                                                    {{ asset.title }}
                                                </div>
                            </router-link>

                            <!-- Panoramas -->
                            <router-link
                            v-if="viewType === 'panoramas' || viewType === 'virtual-tour' || viewType === 'balcony-views'"
                            :to="{
                                name:  'AssetViewDetail',
                                params: {
                                assetType: this.assetType,
                                viewType: this.viewType,
                                order: asset.id
                                }
                            }"
                            class="p-link"
                            >
                            <img :src="theme.cdnUrl + '/assets/' + this.assetType + '/' + this.viewType + '/' + asset.LinkName + '?width=700'" class="img-fluid">
                            <div class="render-name">
                                                    {{ asset.title }}
                                                </div>
                            </router-link>

                            <!-- Floorplans -->
                            <router-link
                                v-if="viewType === 'floorplans'"
                                :to="{
                                    name:  'AssetViewDetail',
                                    params: {
                                    assetType: this.assetType,
                                    viewType: this.viewType,
                                    order: asset.id
                                    }
                                }"
                                class="f-link"
                                >
                                    <img :src="theme.cdnUrl + '/assets/' + this.assetType + '/' + this.viewType + '/' + asset.LinkName + '?width=700'" class="img-fluid">
                                    <div class="render-name">
                                        {{ asset.title }}
                                    </div>
                            </router-link>

                            <!-- PDF -->
                            <router-link
                                v-if="viewType === 'pdf'"
                                :to="{
                                    name:  'AssetViewDetail',
                                    params: {
                                    assetType: this.assetType,
                                    viewType: this.viewType,
                                    order: asset.id
                                    }
                                }"
                                class="f-link"
                                >
                                    <img :src="theme.cdnUrl + '/assets/' + this.assetType + '/' + this.viewType + '/' + asset.LinkName + '?width=700'" class="img-fluid">
                                    <div class="render-name">
                                        {{ asset.title }}
                                    </div>
                            </router-link>

                    </div>
                </div>
            </div>
            
        </div>
    </div>
  </div>
  
  <ViewBotNav />
  <OffCanvas />
</template>

<script>
import ViewTopNav from '@/components/ViewTopNav.vue';
import ViewBotNav from '@/components/ViewBotNav.vue';
import OffCanvas from "@/components/OffCanvas.vue";
import global from '../global';
import BunnyNetService from "@/services/BunnyNetService.js";
import { unslugify } from "unslugify";

export default {
  name: "AssetView",
  props: [
    'assetType',
    'viewType',
    'lid',
    'collectionId'
  ],
  components: {
    ViewTopNav,
    ViewBotNav,
    OffCanvas
  },
  data() {
    return {
      assetTypes: null,
      assetOrder: null,
      folderStructure: encodeURIComponent(this.theme.devFolder + '/assets/' + this.assetType + '/' + this.viewType + '/'),
      videoStatus: false,
      assetNames: null,
      assetUrls: null
    }
  },
  created() {
    if (this.assetType === 'Ground Floor') {
      if (this.viewType === 'videos') {
        this.assetOrder = this.theme.bed1Asset.videos
        this.assetNames = this.theme.bed1Asset.videosName
      } else if (this.viewType === 'floorplans') {
        this.assetOrder = this.theme.bed1Asset.floorplans
        this.assetNames = this.theme.bed1Asset.floorplansName
        this.assetUrls = this.theme.bed1Asset.floorplansUrl
      } else if (this.viewType === 'panoramas' || this.viewType === 'virtual-tour') {
        this.assetOrder = this.theme.bed1Asset.panoramas
        this.assetNames = this.theme.bed1Asset.panoramasName
        this.assetUrls = this.theme.bed1Asset.panoramasUrl
      } else if (this.viewType === 'balcony-views') {
        this.assetOrder = this.theme.bed1Asset.balcony
        this.assetNames = this.theme.bed1Asset.balconyName
        this.assetUrls = this.theme.bed1Asset.balconyUrl
      } else if (this.viewType === 'renders') {
        this.assetOrder = this.theme.bed1Asset.renders
        this.assetNames = this.theme.bed1Asset.rendersName
        this.assetUrls = this.theme.bed1Asset.rendersUrl
      } else if (this.viewType === 'range') {
        this.assetOrder = this.theme.bed1Asset.range
        this.assetNames = this.theme.bed1Asset.rangeName
        this.assetUrls = this.theme.bed1Asset.rangeUrl
      }  else if (this.viewType === 'pdf') {
        this.assetOrder = this.theme.bed1Asset.pdf
        this.assetNames = this.theme.bed1Asset.pdfName
        this.assetUrls = this.theme.bed1Asset.pdfUrl
      }
    } else if (this.assetType === 'First Floor') {
      if (this.viewType === 'videos') {
        this.assetOrder = this.theme.bed2Asset.videos
        this.assetNames = this.theme.bed2Asset.videosName
      } else if (this.viewType === 'floorplans') {
        this.assetOrder = this.theme.bed2Asset.floorplans
        this.assetNames = this.theme.bed2Asset.floorplansName
        this.assetUrls = this.theme.bed2Asset.floorplansUrl
      } else if (this.viewType === 'panoramas' || this.viewType === 'virtual-tour') {
        this.assetOrder = this.theme.bed2Asset.panoramas
        this.assetNames = this.theme.bed2Asset.panoramasName
        this.assetUrls = this.theme.bed2Asset.panoramasUrl
      } else if (this.viewType === 'balcony-views') {
        this.assetOrder = this.theme.bed2Asset.balcony
        this.assetNames = this.theme.bed2Asset.balconyName
        this.assetUrls = this.theme.bed2Asset.balconyUrl
      } else if (this.viewType === 'renders') {
        this.assetOrder = this.theme.bed2Asset.renders
        this.assetNames = this.theme.bed2Asset.rendersName
        this.assetUrls = this.theme.bed2Asset.rendersUrl
      } else if (this.viewType === 'range') {
        this.assetOrder = this.theme.bed2Asset.range
        this.assetNames = this.theme.bed2Asset.rangeName
        this.assetUrls = this.theme.bed2Asset.rangeUrl
      }  else if (this.viewType === 'pdf') {
        this.assetOrder = this.theme.bed2Asset.pdf
        this.assetNames = this.theme.bed2Asset.pdfName
        this.assetUrls = this.theme.bed2Asset.pdfUrl
      }
    } else if (this.assetType === 'Second Floor') {
      if (this.viewType === 'videos') {
        this.assetOrder = this.theme.bed3Asset.videos
        this.assetNames = this.theme.bed3Asset.videosName
      } else if (this.viewType === 'floorplans') {
        this.assetOrder = this.theme.bed3Asset.floorplans
        this.assetNames = this.theme.bed3Asset.floorplansName
        this.assetUrls = this.theme.bed3Asset.floorplansUrl
      } else if (this.viewType === 'panoramas' || this.viewType === 'virtual-tour') {
        this.assetOrder = this.theme.bed3Asset.panoramas
        this.assetNames = this.theme.bed3Asset.panoramasName
        this.assetUrls = this.theme.bed3Asset.panoramasUrl
      } else if (this.viewType === 'balcony-views') {
        this.assetOrder = this.theme.bed3Asset.balcony
        this.assetNames = this.theme.bed3Asset.balconyName
        this.assetUrls = this.theme.bed3Asset.balconyUrl
      } else if (this.viewType === 'renders') {
        this.assetOrder = this.theme.bed3Asset.renders
        this.assetNames = this.theme.bed3Asset.rendersName
        this.assetUrls = this.theme.bed3Asset.rendersUrl
      } else if (this.viewType === 'range') {
        this.assetOrder = this.theme.bed3Asset.range
        this.assetNames = this.theme.bed3Asset.rangeName
        this.assetUrls = this.theme.bed3Asset.rangeUrl
      }  else if (this.viewType === 'pdf') {
        this.assetOrder = this.theme.bed3Asset.pdf
        this.assetNames = this.theme.bed3Asset.pdfName
        this.assetUrls = this.theme.bed3Asset.pdfUrl
      }
    } else if (this.assetType === 'Riva Offices') {
      if (this.viewType === 'videos') {
        this.assetOrder = this.theme.bed1Asset.videos
        this.assetNames = this.theme.bed1Asset.videosName
      } else if (this.viewType === 'floorplans') {
        this.assetOrder = this.theme.bed1Asset.floorplans
        this.assetNames = this.theme.bed1Asset.floorplansName
        this.assetUrls = this.theme.bed1Asset.floorplansUrl
      } else if (this.viewType === 'panoramas' || this.viewType === 'virtual-tour') {
        this.assetOrder = this.theme.bed1Asset.panoramas
        this.assetNames = this.theme.bed1Asset.panoramasName
        this.assetUrls = this.theme.bed1Asset.panoramasUrl
      } else if (this.viewType === 'balcony-views') {
        this.assetOrder = this.theme.bed1Asset.balcony
        this.assetNames = this.theme.bed1Asset.balconyName
        this.assetUrls = this.theme.bed1Asset.balconyUrl
      } else if (this.viewType === 'renders') {
        this.assetOrder = this.theme.bed1Asset.renders
        this.assetNames = this.theme.bed1Asset.rendersName
        this.assetUrls = this.theme.bed1Asset.rendersUrl
      } else if (this.viewType === 'range') {
        this.assetOrder = this.theme.bed1Asset.range
        this.assetNames = this.theme.bed1Asset.rangeName
        this.assetUrls = this.theme.bed1Asset.rangeUrl
      }  else if (this.viewType === 'pdf') {
        this.assetOrder = this.theme.bed1Asset.pdf
        this.assetNames = this.theme.bed1Asset.pdfName
        this.assetUrls = this.theme.bed1Asset.pdfUrl
      }
    }



    // Video type
    if(this.viewType === 'videos') {
      BunnyNetService.getVideos(this.lid, this.collectionId)
      .then(response => {
        // Remap array (inject asset names)
        console.log(response)
        let j = 0;
        const menuItems = response.data.items.map((item) => {
			if (this.assetNames && this.assetNames.length >= 1){
				return {
					guid: item.guid,
					videoLibraryId: item.videoLibraryId,
					thumbnailFileName: item.thumbnailFileName,
					ObjectName: item.ObjectName,
					title: this.assetNames[j],
					id: j++
				};
			} else {
				return {
					guid: item.guid,
					videoLibraryId: item.videoLibraryId,
					thumbnailFileName: item.thumbnailFileName,
					ObjectName: item.ObjectName,
					title: unslugify(item.title.split('.').slice(0,-1).join(".")).replace("hyphen","-"),
					id: j++
				};
			}
        });

        // Custom order array
        if ( this.assetOrder && this.assetOrder.length >= 1 ) {
          this.assetTypes = this.reorder(menuItems, this.assetOrder);
        } else {
          this.assetTypes = menuItems;
        }

        this.videoStatus = true
      })
      .catch(error => {
        console.log(error)
      })
    // Renders, Panoramas, Floorplans type
    } else {
      BunnyNetService.getRenders(this.folderStructure, this.assetType)
      .then(response => {
        let j = 0;
        // Remap array
        console.log(response)
        const menuItems = response.data.map((item) => {
          if (this.assetUrls && this.assetUrls.length >= 1){
            return {
              Guid: item.Guid,
              ObjectName: unslugify(item.ObjectName),
              LinkName: item.ObjectName,
              Path: item.Path,
              IsDirectory: item.IsDirectory,
              StorageZoneName: item.StorageZoneName,
              title: this.assetNames[j],
              url: this.assetUrls[j],
              id: j++
            };
          }else {
			return {
              Guid: item.Guid,
              ObjectName: unslugify(item.ObjectName),
              LinkName: item.ObjectName,
              Path: item.Path,
              IsDirectory: item.IsDirectory,
              StorageZoneName: item.StorageZoneName,
              title: unslugify(item.ObjectName.split('.').slice(0,-1).join(".")).replace("hyphen","-"),
              url: [],
              id: j++
            };
          }
        });

        // Custom order array
        if( this.assetOrder && this.assetOrder.length >= 1 ) {
          this.assetTypes = this.reorder(menuItems, this.assetOrder);
        } else {
          this.assetTypes = menuItems
        }
      })
    }
  },
  methods: {
    reorder: function(data_array, real_order) {
      var i, d = {}, result = [];
      for(i=0; i<data_array.length; ++i) {
        d[data_array[i].id] = data_array[i];
      }

      for(i=0; i<real_order.length; ++i) {
        result.push(d[real_order[i]]);
      }

      return result;
    }
  },
  setup () {
    const {theme} = global

    return {
      theme
    }
  }
}
</script>
